
import { styled, alpha } from '@mui/material/styles';
import Box  from '@mui/material/Box'
import { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Router from "next/router";
import dynamic from 'next/dynamic';
const StyledBox = dynamic(() => import("@components/Custom/Box/StyledBox"));


    
  const SearchIconWrapper = styled('button')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    all: 'unset',
    top: '25%',
    cursor: 'pointer',
  }));
    
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '96%',
    // [theme.breakpoints.up('xl')]: {
    //     width: '93%',
    //   },
    // [theme.breakpoints.up('lg')]: {
    //     width: '93%',
    //   },
    // [theme.breakpoints.up('md')]: {
    //     width: '93%',
    //   },
    // [theme.breakpoints.up('smrfedefeee')]: {
    //     width: '90%',
    //   },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(0.5em + ${theme.spacing(1.35)})`,
      paddingRight: `calc(0.5em + ${theme.spacing(0.55)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  }));
  
const HeaderSearch = () => {
    // const { user, isAuthenticated } = useSelector((state) => state.authReducer);
    // const { cart } = useSelector((state) => state.cartReducer);
    const [placeholder, setPlaceholder] = useState("Search")
    // const {dialog, handleDialog} = useDialog();
    // const router= useRouter()
    const validateSearchForm = (event) => {
        event.preventDefault();
        const searchTerm = event.target.elements.term.value
        if (searchTerm.trim().length > 0) {
        Router.push("/search?term=" + searchTerm);
        }
    };
    return (
        <Box sx={{
            marginTop: 'auto',
            display: 'flex',
            minWidth:'100%',
            maxWidth:'100%'
          }} 
          className='ml-4 mb-2 w-100 mr-1'
          >
            <form onSubmit={validateSearchForm} className='w-100 mr-3'>
              <Box className='w-100' sx={{position:'relative', borderRadius:15, border:1, borderColor:'#ccc',  minWidth:'auto', maxWidth:'100%', ":hover":{backgroundColor:alpha('#ccc', 0.25)}}}>
                <SearchIconWrapper aria-label='search_button' name="search_button" className="position-absolute" 
                    sx={{
                      position: 'absolute', right: 10
                    }}
                  >
                  <StyledBox className='p-0'>
                    <SearchIcon />
                  </StyledBox>
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={placeholder}
                  inputProps={{ 'aria-label': 'search', name: 'search' }}
                  type="text"
                  name="term"
                  id="term"
                />
              </Box>
            </form>
        </Box>
    )
}

export default HeaderSearch